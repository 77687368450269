@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

body {
  background-color: #f2f8fc;
  font-family: "Plus Jakarta Sans", sans-serif;
}

/* font */

@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

.navLogoText {
  color: #fff;
  text-align: center;
  font-family: "Gilroy", sans-serif !important;
  font-weight: 900; /* Use 900 to apply Gilroy-Black */
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 900;
  line-height: 111.5%; /* 2.09063rem */
  letter-spacing: -0.01875rem;
}
.tickerText {
  color: #2E3478;
  text-align: center;
  font-family: "Gilroy", sans-serif !important;
  font-weight: 900; /* Use 900 to apply Gilroy-Black */
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 900;
  line-height: 111.5%; /* 2.09063rem */
  letter-spacing: -0.01875rem;
}

/* gradients */

.hero-heading-gradient {
  background: linear-gradient(
    92.07deg,
    #36a1cf -0.25%,
    #130f40 49.19%,
    #5d9c6b 96.05%
  );
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.about-heading-gradient {
  background: linear-gradient(90deg, #7fd7a4 0%, #79c8d1 51.92%, #2586c5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.step-card-border-gradient {
  border-width: 3px;
  border-style: solid;
  border-image: linear-gradient(to bottom, #7fd7a5, #7fd7a500) 1 100%;
}

.beneficiary-active-wrapper {
  display: inline-block;
  border-radius: 8px;
  overflow: hidden;
}

.beneficiary-active-card-gradient {
  border-image: linear-gradient(to right, #449ecf, #68bcc7, #76c9bc, #7bd2aa) 1;
}

.analytics-active-menu-gradient {
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(to right, #449ecf, #68bcc7, #76c9bc, #7bd2aa);
  border-image-slice: 1;
  border-radius: 10px;
}
